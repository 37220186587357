<template>
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					스탬프
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="content">
				<div class="box">
					<!-- tab -->
					<div class="tab tab--half tab--fit">
						<a href="#">스탬프카드</a>
						<a href="#" class="active">적립내역</a>
					</div>
					<!-- 스탬프 -->
					<div class="stamplist">
						<!-- 스탬프 현황 -->
						<div class="stamp__status">
							<div class="stamp__box">
								<i class="icon icon-stamp"></i><br>
								누적 적립스탬프
								<strong>3,000개</strong>
							</div>
							<div class="stamp__box">
								<i class="icon icon-point2"></i><br>
								누적 적립포인트
								<strong>3,000P</strong>
							</div>
						</div>
						<!-- 스탬프 적립내역 -->
						<ul class="info info--small">
							<li class="subject">
								<h4 class="info__subject" style="padding-left:34px">
									적립일
								</h4>
								<h4 class="info__subject">
									적립내역
								</h4>
							</li>
							<li>
								<h4 class="info__title">
									2021.02.24
								</h4>
								<div class="info__wrap">
									<div class="info__text">
										포인트
										<strong class="black">5,000P</strong>
									</div>
								</div>
							</li>
							<li>
								<h4 class="info__title">
									2021.02.24
								</h4>
								<div class="info__wrap">
									<div class="info__text">
										스탬프
										<strong class="black">+1</strong>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="pager">
			<a href="#">
				<i class="icon icon-first"></i>
			</a>
			<a href="#">
				<i class="icon icon-left--light"></i>
			</a>
			<a href="#">1</a>
			<a href="#" class="active">2</a>
			<a href="#">3</a>
			<a href="#">4</a>
			<a href="#">5</a>
			<a href="#">
				<i class="icon icon-right--light"></i>
			</a>
			<a href="#">
				<i class="icon icon-last"></i>
			</a>
		</div>
	</div>
</template>